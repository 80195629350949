/* --------------- variables --------------------- */

/* Break Point */
$breakpoint_sp        :1000px;
$breakpoint_tb        :1000px;
$breakpoint_pc        :$breakpoint_sp + 1;


/* Color */
$c_base : #231815;/*サイトカラー*/
$c_text : #231815; /*テキストに使うフォントカラー*/
$c_href : #231815; /*テキスト内で使うリンクカラー*/


/* font */
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:wght@400;600;700&family=Shippori+Mincho+B1:wght@400;600;700&display=swap');

$ff_jp : 'Shippori Mincho B1', serif;
$ff_en : 'Crimson Text', serif;
