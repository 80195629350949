@charset "UTF-8";
/* --------------- variables --------------------- */
/* Break Point */
/* Color */
/*サイトカラー*/
/*テキストに使うフォントカラー*/
/*テキスト内で使うリンクカラー*/
/* font */
@import url("https://fonts.googleapis.com/css2?family=Crimson+Text:wght@400;600;700&family=Shippori+Mincho+B1:wght@400;600;700&display=swap");
@media screen and (max-width: 1000px) {
  body {
    padding-top: 55px; }
  .contents {
    padding-bottom: 70px; }
    .contents.pB0 {
      padding-bottom: 0; }
    .contents section + section {
      margin-top: 60px; }
      .contents section + section.bg_gray {
        padding: 60px 0; }
    .contents .bg_gray {
      background-color: #f1f1f1; }
  .page_head_wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 130px; }
    .page_head_wrap .page_title {
      text-align: center;
      line-height: 1.3;
      text-transform: uppercase;
      font-weight: normal;
      font-size: 28px; }
    .page_head_wrap .page_sub_title {
      text-align: center;
      margin: 0;
      font-size: 11px; }
  .recipe_list > .item {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto; }
    .recipe_list > .item + .item {
      margin-top: 40px; }
    .recipe_list > .item .recipe_img {
      width: 100%;
      overflow: hidden;
      height: 190px; }
      .recipe_list > .item .recipe_img a {
        width: 100%;
        height: 100%;
        display: block;
        text-decoration: none;
        color: #231815; }
    .recipe_list > .item h3 {
      color: #fff;
      display: inline-block;
      background-color: #231815;
      line-height: 1.2;
      font-weight: 600;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      font-size: 16px;
      padding: 10px 15px; }
  .recipe_list .product {
    margin-top: 10px; }
    .recipe_list .product > .item {
      width: 100%;
      display: flex;
      align-items: center; }
      .recipe_list .product > .item:nth-of-type(n + 2) {
        margin-top: 5px; }
      .recipe_list .product > .item .img {
        background-color: #f2f2f2;
        width: 90px;
        padding: 5px;
        text-align: center; }
        .recipe_list .product > .item .img img {
          max-width: 70%; }
      .recipe_list .product > .item .product_name {
        text-transform: uppercase;
        width: calc(100% - 90px);
        padding-left: 15px; }
        .recipe_list .product > .item .product_name .title {
          line-height: 1.2;
          font-weight: 600;
          font-size: 18px; }
        .recipe_list .product > .item .product_name .sub_title {
          font-family: "Shippori Mincho B1", serif;
          line-height: 1.2;
          margin: 0;
          font-size: 13px; }
  .breadcrumbs {
    display: flex;
    margin: 0 20px;
    padding-top: 20px;
    padding-bottom: 20px; }
    .breadcrumbs > span {
      display: inline-block;
      position: relative;
      padding-right: 15px;
      font-size: 13px;
      color: #000;
      font-size: 12px; }
      .breadcrumbs > span:not(:last-of-type)::after {
        content: "／";
        display: inline-block;
        margin-left: 15px; }
      .breadcrumbs > span a {
        color: #231815; }
  .recipe_main img {
    width: 100%; }
  .recipe_wrap {
    padding: 50px 0; }
  .ingredient {
    background-color: #f2f2f2;
    position: relative;
    width: calc(100% + 40px);
    padding: 45px 40px 40px;
    transform: translateX(-20px); }
    .ingredient h2 {
      font-weight: normal;
      position: absolute;
      font-size: 23px;
      top: -1em;
      left: 50%;
      transform: translateX(-50%); }
    .ingredient .sub_h2 {
      position: absolute;
      margin: 0;
      font-size: 11px;
      top: 1.2em;
      left: 50%;
      transform: translateX(-50%); }
    .ingredient .ingredient_cont > div:last-of-type {
      margin-top: 10px; }
    .ingredient .ingredient_cont > div dl {
      display: flex;
      justify-content: space-between;
      position: relative;
      align-items: center; }
      .ingredient .ingredient_cont > div dl:not(:first-of-type) {
        margin-top: 5px; }
      .ingredient .ingredient_cont > div dl:before {
        content: "";
        background: url(../images/second/dot.png) repeat-x;
        width: 100%;
        height: 2px;
        position: absolute;
        left: 0;
        top: calc(50% - 1px); }
      .ingredient .ingredient_cont > div dl dt {
        background-color: #f2f2f2;
        position: relative;
        z-index: 1;
        padding-right: 10px; }
      .ingredient .ingredient_cont > div dl dd {
        background-color: #f2f2f2;
        position: relative;
        z-index: 1;
        padding-left: 10px; }
  .howto {
    margin-top: 40px; }
    .howto h2 {
      font-weight: normal;
      text-align: center;
      line-height: 1.3;
      font-size: 23px; }
    .howto .sub_h2 {
      text-align: center;
      margin: 0;
      font-size: 11px; }
    .howto .howto_cont {
      border-top: 1px solid #231815;
      margin-top: 15px;
      padding-top: 15px; }
      .howto .howto_cont dl {
        display: flex;
        position: relative; }
        .howto .howto_cont dl + dl {
          margin-top: 5px; }
        .howto .howto_cont dl:before {
          content: "";
          display: block;
          background: url(../images/second/dot.png) repeat-x;
          width: 100%;
          height: 2px;
          position: absolute;
          left: 0;
          top: 21px; }
        .howto .howto_cont dl dt {
          font-family: "Crimson Text", serif;
          padding: 0 15px 0 5px;
          background-color: #fff;
          position: relative;
          z-index: 1;
          font-size: 28px;
          width: 24px;
          margin-right: 24px; }
        .howto .howto_cont dl dd {
          background-color: #fff;
          position: relative;
          z-index: 1;
          font-size: 15px;
          width: calc(100% - 48px);
          padding-left: 10px;
          padding-top: 8px; }
  .product_used {
    background-color: #f2f2f2;
    padding: 40px 0 110px;
    margin-bottom: -61px; }
    .product_used .product .img {
      text-align: center; }
      .product_used .product .img img {
        max-width: 100%; }
    .product_used .product .text_area {
      text-align: center;
      margin-top: 20px; }
      .product_used .product .text_area .used {
        color: #fff;
        background-color: #231815;
        line-height: 1;
        display: inline-block;
        padding: 6px 10px 3px; }
      .product_used .product .text_area .title {
        line-height: 1.2;
        font-weight: normal;
        text-transform: uppercase;
        font-size: 20px;
        margin-top: 10px; }
      .product_used .product .text_area .sub_title {
        font-family: "Shippori Mincho B1", serif;
        line-height: 1.2;
        font-size: 11px;
        margin: 3px 0 0 0; }
      .product_used .product .text_area .text {
        border-top: 1px solid #c8c8c8;
        padding-top: 15px;
        margin-top: 15px;
        text-align: left; }
      .product_used .product .text_area .btn {
        text-align: center;
        margin-top: 20px; }
        .product_used .product .text_area .btn .more_btn {
          border-radius: 3px;
          line-height: 1;
          display: inline-block;
          text-decoration: none;
          border: 1px solid #231815;
          transition: .3s;
          text-align: center;
          color: #231815;
          font-size: 14px;
          padding: 15px 40px;
          width: 90%; }
  .product_main .common_inner .img {
    text-align: center; }
    .product_main .common_inner .img img {
      max-width: 100%; }
  .product_main .common_inner .text_area {
    margin-top: 30px; }
    .product_main .common_inner .text_area p.jp {
      margin: 0; }
    .product_main .common_inner .text_area p.en {
      margin: 30px 0 0 0; }
    .product_main .common_inner .text_area .price_link {
      margin: 30px 0 0 0; }
      .product_main .common_inner .text_area .price_link .price {
        text-align: center;
        font-size: 28px; }
        .product_main .common_inner .text_area .price_link .price span {
          font-size: 14px; }
      .product_main .common_inner .text_area .price_link .btn {
        text-align: center;
        margin-top: 10px; }
        .product_main .common_inner .text_area .price_link .btn .shop_btn {
          border-radius: 3px;
          line-height: 1;
          display: inline-block;
          text-decoration: none;
          border: 1px solid #231815;
          transition: .3s;
          text-align: center;
          color: #231815;
          position: relative;
          font-size: 14px;
          padding: 15px 40px 15px 30px;
          width: 90%; }
          .product_main .common_inner .text_area .price_link .btn .shop_btn .icon {
            display: inline-block; }
            .product_main .common_inner .text_area .price_link .btn .shop_btn .icon:before {
              content: "";
              display: inline-block;
              background: url(../images/common/icon_cart.svg) no-repeat;
              background-size: cover;
              width: 16px;
              height: 16px;
              transition: .2s;
              margin-right: 10px;
              transform: translateY(2px); }
  .product_wrap h2 {
    font-weight: normal;
    text-align: center;
    line-height: 1.3;
    font-size: 28px;
    margin-top: 60px; }
  .product_wrap .sub_h2 {
    text-align: center;
    margin: 0;
    font-size: 11px; }
  .product_wrap .feature_main {
    margin-top: 40px; }
    .product_wrap .feature_main img {
      max-width: 100%; }
  .product_wrap .feature_list {
    margin-top: 60px; }
    .product_wrap .feature_list .item:not(:first-of-type) {
      margin-top: 60px; }
    .product_wrap .feature_list .item .img {
      text-align: center; }
    .product_wrap .feature_list .item .text_area {
      margin-top: 20px; }
      .product_wrap .feature_list .item .text_area .title {
        line-height: 1.3;
        display: flex;
        align-items: center; }
        .product_wrap .feature_list .item .text_area .title .num {
          display: block;
          border-right: 1px solid #000;
          font-size: 18px;
          width: 30px;
          padding-top: 5px; }
        .product_wrap .feature_list .item .text_area .title h3 {
          font-weight: normal;
          font-size: 19px;
          width: calc(100% - 30px);
          padding-left: 15px; }
      .product_wrap .feature_list .item .text_area p.jp {
        margin: 20px 0 0 0; }
      .product_wrap .feature_list .item .text_area p.en {
        margin: 20px 0 0 0; }
  .product_info {
    background-color: #fff;
    padding: 30px 30px 45px; }
    .product_info dl {
      border-bottom: 1px solid #c9c9c9;
      padding: 15px 0; }
      .product_info dl dt {
        margin-bottom: 10px; }
    .product_info .btn {
      text-align: center;
      margin-top: 30px; }
      .product_info .btn .shop_btn {
        border-radius: 3px;
        line-height: 1;
        display: inline-block;
        text-decoration: none;
        border: 1px solid #231815;
        transition: .3s;
        text-align: center;
        color: #231815;
        position: relative;
        font-size: 14px;
        padding: 15px 40px 15px 30px;
        width: 100%; }
        .product_info .btn .shop_btn .icon {
          display: inline-block; }
          .product_info .btn .shop_btn .icon:before {
            content: "";
            display: inline-block;
            background: url(../images/common/icon_cart.svg) no-repeat;
            background-size: cover;
            width: 16px;
            height: 16px;
            transition: .2s;
            margin-right: 10px;
            transform: translateY(2px); }
  .related_recipe {
    padding-bottom: 50px; }
    .related_recipe h2 {
      font-weight: normal;
      text-align: center;
      line-height: 1.3;
      font-size: 28px; }
    .related_recipe .sub_h2 {
      text-align: center;
      margin: 0;
      font-size: 11px; }
    .related_recipe .related_recipe_list {
      margin-top: 30px; }
      .related_recipe .related_recipe_list .item {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: auto; }
        .related_recipe .related_recipe_list .item .recipe_img {
          width: 100%;
          overflow: hidden;
          height: 230px; }
          .related_recipe .related_recipe_list .item .recipe_img a {
            width: 100%;
            height: 100%;
            display: block;
            text-decoration: none;
            color: #231815; }
        .related_recipe .related_recipe_list .item h3 {
          color: #fff;
          display: inline-block;
          background-color: #231815;
          line-height: 1.2;
          font-weight: 600;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          font-size: 16px;
          padding: 10px 15px; }
      .related_recipe .related_recipe_list .slick-dots {
        text-align: left;
        padding-left: 15px;
        padding-right: 70px; }
        .related_recipe .related_recipe_list .slick-dots li {
          display: inline-block;
          margin: 0 8px;
          padding-right: 10px;
          margin: 0 5px; }
          .related_recipe .related_recipe_list .slick-dots li button {
            position: relative;
            text-indent: -9999px; }
            .related_recipe .related_recipe_list .slick-dots li button:before {
              content: '';
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: #fff;
              position: absolute;
              border: 1px solid #231815;
              top: 0;
              left: 0;
              width: 6px;
              height: 6px; }
          .related_recipe .related_recipe_list .slick-dots li.slick-active button:before {
            background-color: #231815; }
        .related_recipe .related_recipe_list .slick-dots button {
          margin: 0;
          padding: 0;
          background: none;
          border: none;
          border-radius: 0;
          outline: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none; }
      .related_recipe .related_recipe_list .slick-arrow {
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        padding: 0;
        appearance: none;
        font-size: 0;
        display: inline-block;
        width: 26px;
        height: 26px;
        position: absolute;
        z-index: 2; }
        .related_recipe .related_recipe_list .slick-arrow:hover {
          cursor: pointer;
          opacity: .7; }
        .related_recipe .related_recipe_list .slick-arrow.slick-prev {
          right: 0px;
          bottom: 0px; }
          .related_recipe .related_recipe_list .slick-arrow.slick-prev:after {
            content: "";
            width: 16px;
            height: 16px;
            border: 0px;
            border-bottom: solid 1px #231815;
            border-left: solid 1px #231815;
            transform: rotate(45deg);
            position: absolute;
            top: 0px;
            left: -50px;
            z-index: 2;
            left: -50px;
            width: 10px;
            height: 10px; }
        .related_recipe .related_recipe_list .slick-arrow.slick-next {
          right: 0px;
          bottom: 0px;
          right: 5px; }
          .related_recipe .related_recipe_list .slick-arrow.slick-next:after {
            content: "";
            width: 16px;
            height: 16px;
            border: 0px;
            border-top: solid 1px #231815;
            border-right: solid 1px #231815;
            transform: rotate(45deg);
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 2;
            width: 10px;
            height: 10px; } }

@media screen and (min-width: 1000px) {
  .contents.pB0 {
    padding-bottom: 0; }
  .contents .bg_gray {
    background-color: #f1f1f1; }
  .page_head_wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .page_head_wrap .page_title {
      text-align: center;
      line-height: 1.3;
      text-transform: uppercase;
      font-weight: normal; }
    .page_head_wrap .page_sub_title {
      text-align: center;
      margin: 0; }
  .recipe_list > .item {
    overflow: hidden;
    position: relative; }
    .recipe_list > .item .recipe_img {
      width: 100%;
      overflow: hidden; }
      .recipe_list > .item .recipe_img a {
        width: 100%;
        height: 100%;
        display: block;
        text-decoration: none;
        color: #231815; }
    .recipe_list > .item h3 {
      color: #fff;
      display: inline-block;
      background-color: #231815;
      line-height: 1.2;
      font-weight: 600;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2; }
  .recipe_list .product {
    margin-top: 10px; }
    .recipe_list .product > .item {
      width: 100%;
      display: flex;
      align-items: center; }
      .recipe_list .product > .item:nth-of-type(n + 2) {
        margin-top: 5px; }
      .recipe_list .product > .item .img {
        background-color: #f2f2f2; }
        .recipe_list .product > .item .img img {
          max-width: 70%; }
      .recipe_list .product > .item .product_name {
        text-transform: uppercase; }
        .recipe_list .product > .item .product_name .title {
          line-height: 1.2;
          font-weight: 600; }
        .recipe_list .product > .item .product_name .sub_title {
          font-family: "Shippori Mincho B1", serif;
          line-height: 1.2;
          margin: 0; }
  .breadcrumbs {
    display: flex; }
    .breadcrumbs > span {
      display: inline-block;
      position: relative;
      padding-right: 15px;
      font-size: 13px;
      color: #000; }
      .breadcrumbs > span:not(:last-of-type)::after {
        content: "／";
        display: inline-block;
        margin-left: 15px; }
      .breadcrumbs > span a {
        color: #231815; }
  .recipe_main img {
    width: 100%; }
  .ingredient {
    background-color: #f2f2f2;
    position: relative; }
    .ingredient h2 {
      font-weight: normal;
      position: absolute; }
    .ingredient .sub_h2 {
      position: absolute;
      margin: 0; }
    .ingredient .ingredient_cont > div dl {
      display: flex;
      justify-content: space-between;
      position: relative;
      align-items: center; }
      .ingredient .ingredient_cont > div dl:not(:first-of-type) {
        margin-top: 5px; }
      .ingredient .ingredient_cont > div dl:before {
        content: "";
        background: url(../images/second/dot.png) repeat-x;
        width: 100%;
        height: 2px;
        position: absolute;
        left: 0;
        top: calc(50% - 1px); }
      .ingredient .ingredient_cont > div dl dt {
        background-color: #f2f2f2;
        position: relative;
        z-index: 1;
        padding-right: 10px; }
      .ingredient .ingredient_cont > div dl dd {
        background-color: #f2f2f2;
        position: relative;
        z-index: 1;
        padding-left: 10px; }
  .howto h2 {
    font-weight: normal;
    text-align: center;
    line-height: 1.3; }
  .howto .sub_h2 {
    text-align: center;
    margin: 0; }
  .howto .howto_cont {
    border-top: 1px solid #231815; }
    .howto .howto_cont dl {
      display: flex;
      position: relative; }
      .howto .howto_cont dl:before {
        content: "";
        display: block;
        background: url(../images/second/dot.png) repeat-x;
        width: 100%;
        height: 2px;
        position: absolute;
        left: 0;
        top: 21px; }
      .howto .howto_cont dl dt {
        font-family: "Crimson Text", serif;
        padding: 0 15px 0 5px;
        background-color: #fff;
        position: relative;
        z-index: 1; }
      .howto .howto_cont dl dd {
        background-color: #fff;
        position: relative;
        z-index: 1; }
  .product_used {
    background-color: #f2f2f2; }
    .product_used .product .img {
      text-align: center; }
      .product_used .product .img img {
        max-width: 100%; }
    .product_used .product .text_area .used {
      color: #fff;
      background-color: #231815;
      line-height: 1;
      display: inline-block;
      padding: 6px 10px 3px; }
    .product_used .product .text_area .title {
      line-height: 1.2;
      font-weight: normal;
      text-transform: uppercase; }
    .product_used .product .text_area .sub_title {
      font-family: "Shippori Mincho B1", serif;
      line-height: 1.2; }
    .product_used .product .text_area .text {
      border-top: 1px solid #c8c8c8; }
    .product_used .product .text_area .btn .more_btn {
      border-radius: 3px;
      line-height: 1;
      display: inline-block;
      text-decoration: none;
      border: 1px solid #231815;
      transition: .3s;
      text-align: center;
      color: #231815; }
  .product_main .common_inner .img {
    text-align: center; }
    .product_main .common_inner .img img {
      max-width: 100%; }
  .product_main .common_inner .text_area p.jp {
    margin: 0; }
  .product_main .common_inner .text_area .price_link .btn .shop_btn {
    border-radius: 3px;
    line-height: 1;
    display: inline-block;
    text-decoration: none;
    border: 1px solid #231815;
    transition: .3s;
    text-align: center;
    color: #231815;
    position: relative; }
    .product_main .common_inner .text_area .price_link .btn .shop_btn .icon {
      display: inline-block; }
      .product_main .common_inner .text_area .price_link .btn .shop_btn .icon:before {
        content: "";
        display: inline-block;
        background: url(../images/common/icon_cart.svg) no-repeat;
        background-size: cover;
        width: 16px;
        height: 16px;
        transition: .2s;
        margin-right: 10px;
        transform: translateY(2px); }
  .product_wrap h2 {
    font-weight: normal;
    text-align: center;
    line-height: 1.3; }
  .product_wrap .sub_h2 {
    text-align: center;
    margin: 0; }
  .product_wrap .feature_main img {
    max-width: 100%; }
  .product_wrap .feature_list .item .text_area .title {
    line-height: 1.3; }
    .product_wrap .feature_list .item .text_area .title .num {
      display: block;
      border-right: 1px solid #000; }
    .product_wrap .feature_list .item .text_area .title h3 {
      font-weight: normal; }
  .product_info {
    background-color: #fff; }
    .product_info dl {
      border-bottom: 1px solid #c9c9c9; }
    .product_info .btn {
      text-align: center; }
      .product_info .btn .shop_btn {
        border-radius: 3px;
        line-height: 1;
        display: inline-block;
        text-decoration: none;
        border: 1px solid #231815;
        transition: .3s;
        text-align: center;
        color: #231815;
        position: relative; }
        .product_info .btn .shop_btn .icon {
          display: inline-block; }
          .product_info .btn .shop_btn .icon:before {
            content: "";
            display: inline-block;
            background: url(../images/common/icon_cart.svg) no-repeat;
            background-size: cover;
            width: 16px;
            height: 16px;
            transition: .2s;
            margin-right: 10px;
            transform: translateY(2px); }
  .related_recipe h2 {
    font-weight: normal;
    text-align: center;
    line-height: 1.3; }
  .related_recipe .sub_h2 {
    text-align: center;
    margin: 0; }
  .related_recipe .related_recipe_list .item {
    overflow: hidden;
    position: relative; }
    .related_recipe .related_recipe_list .item .recipe_img {
      width: 100%;
      overflow: hidden; }
      .related_recipe .related_recipe_list .item .recipe_img a {
        width: 100%;
        height: 100%;
        display: block;
        text-decoration: none;
        color: #231815; }
    .related_recipe .related_recipe_list .item h3 {
      color: #fff;
      display: inline-block;
      background-color: #231815;
      line-height: 1.2;
      font-weight: 600;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2; }
  .related_recipe .related_recipe_list .slick-dots {
    text-align: left; }
    .related_recipe .related_recipe_list .slick-dots li {
      display: inline-block;
      margin: 0 8px;
      padding-right: 10px; }
      .related_recipe .related_recipe_list .slick-dots li button {
        position: relative;
        text-indent: -9999px; }
        .related_recipe .related_recipe_list .slick-dots li button:before {
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #fff;
          position: absolute;
          border: 1px solid #231815;
          top: 0;
          left: 0; }
      .related_recipe .related_recipe_list .slick-dots li.slick-active button:before {
        background-color: #231815; }
    .related_recipe .related_recipe_list .slick-dots button {
      margin: 0;
      padding: 0;
      background: none;
      border: none;
      border-radius: 0;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; }
  .related_recipe .related_recipe_list .slick-arrow {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
    font-size: 0;
    display: inline-block;
    width: 26px;
    height: 26px;
    position: absolute;
    z-index: 2; }
    .related_recipe .related_recipe_list .slick-arrow:hover {
      cursor: pointer;
      opacity: .7; }
    .related_recipe .related_recipe_list .slick-arrow.slick-prev {
      right: 0px;
      bottom: 0px; }
      .related_recipe .related_recipe_list .slick-arrow.slick-prev:after {
        content: "";
        width: 16px;
        height: 16px;
        border: 0px;
        border-bottom: solid 1px #231815;
        border-left: solid 1px #231815;
        transform: rotate(45deg);
        position: absolute;
        top: 0px;
        left: -50px;
        z-index: 2; }
    .related_recipe .related_recipe_list .slick-arrow.slick-next {
      right: 0px;
      bottom: 0px; }
      .related_recipe .related_recipe_list .slick-arrow.slick-next:after {
        content: "";
        width: 16px;
        height: 16px;
        border: 0px;
        border-top: solid 1px #231815;
        border-right: solid 1px #231815;
        transform: rotate(45deg);
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 2; } }

@media print, screen and (min-width: 1001px) {
  body {
    padding-top: 70px; }
  .contents {
    padding-bottom: 120px; }
    .contents.pB0 {
      padding-bottom: 0; }
    .contents section + section {
      margin-top: 110px; }
      .contents section + section.bg_gray {
        padding: 110px 0; }
    .contents .bg_gray {
      background-color: #f1f1f1; }
  .page_head_wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 215px; }
    .page_head_wrap .page_title {
      text-align: center;
      line-height: 1.3;
      text-transform: uppercase;
      font-weight: normal;
      font-size: 38px; }
    .page_head_wrap .page_sub_title {
      text-align: center;
      margin: 0;
      font-size: 13px; }
  .recipe_list {
    display: flex;
    flex-wrap: wrap; }
    .recipe_list > .item {
      overflow: hidden;
      position: relative;
      width: calc(100% / 3 - 2px);
      height: auto; }
      .recipe_list > .item:not(:nth-of-type(3n)) {
        margin-right: 3px; }
      .recipe_list > .item:nth-of-type(n + 4) {
        margin-top: 80px; }
      .recipe_list > .item .recipe_img {
        width: 100%;
        overflow: hidden;
        height: 250px; }
        .recipe_list > .item .recipe_img a {
          width: 100%;
          height: 100%;
          display: block;
          text-decoration: none;
          color: #231815;
          transform: scale(1);
          transition: transform 1.2s cubic-bezier(0.24, 0.12, 0, 1); }
          .recipe_list > .item .recipe_img a:hover {
            transform: scale(1.08);
            cursor: pointer; }
      .recipe_list > .item h3 {
        color: #fff;
        display: inline-block;
        background-color: #231815;
        line-height: 1.2;
        font-weight: 600;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        padding: 10px 20px; }
    .recipe_list .product {
      margin-top: 10px; }
      .recipe_list .product > .item {
        width: 100%;
        display: flex;
        align-items: center; }
        .recipe_list .product > .item:nth-of-type(n + 2) {
          margin-top: 5px; }
        .recipe_list .product > .item .img {
          background-color: #f2f2f2;
          width: 100px;
          padding: 5px;
          text-align: center; }
          .recipe_list .product > .item .img img {
            max-width: 70%; }
        .recipe_list .product > .item .product_name {
          text-transform: uppercase;
          width: calc(100% - 100px);
          padding-left: 20px; }
          .recipe_list .product > .item .product_name .title {
            line-height: 1.2;
            font-weight: 600;
            font-size: 20px; }
          .recipe_list .product > .item .product_name .sub_title {
            font-family: "Shippori Mincho B1", serif;
            line-height: 1.2;
            margin: 0;
            font-size: 12px; }
  .breadcrumbs {
    display: flex;
    width: 96%;
    margin: 0 auto;
    padding-top: 5px;
    padding-bottom: 20px;
    max-width: 1200px; }
    .breadcrumbs > span {
      display: inline-block;
      position: relative;
      padding-right: 15px;
      font-size: 13px;
      color: #000; }
      .breadcrumbs > span:not(:last-of-type)::after {
        content: "／";
        display: inline-block;
        margin-left: 15px; }
      .breadcrumbs > span a {
        color: #231815; }
  .recipe_main img {
    width: 100%; }
  .recipe_wrap {
    padding: 95px 0; }
  .ingredient {
    background-color: #f2f2f2;
    position: relative;
    padding: 60px 100px; }
    .ingredient h2 {
      font-weight: normal;
      position: absolute;
      font-size: 28px;
      top: -1.1em;
      left: 50%;
      transform: translateX(-50%); }
    .ingredient .sub_h2 {
      position: absolute;
      margin: 0;
      font-size: 13px;
      top: 1.2em;
      left: 50%;
      transform: translateX(-50%); }
    .ingredient .ingredient_cont {
      display: flex;
      justify-content: space-between; }
      .ingredient .ingredient_cont > div {
        width: calc(50% - 60px); }
        .ingredient .ingredient_cont > div dl {
          display: flex;
          justify-content: space-between;
          position: relative;
          align-items: center; }
          .ingredient .ingredient_cont > div dl:not(:first-of-type) {
            margin-top: 5px; }
          .ingredient .ingredient_cont > div dl:before {
            content: "";
            background: url(../images/second/dot.png) repeat-x;
            width: 100%;
            height: 2px;
            position: absolute;
            left: 0;
            top: calc(50% - 1px); }
          .ingredient .ingredient_cont > div dl dt {
            background-color: #f2f2f2;
            position: relative;
            z-index: 1;
            padding-right: 10px; }
          .ingredient .ingredient_cont > div dl dd {
            background-color: #f2f2f2;
            position: relative;
            z-index: 1;
            padding-left: 10px; }
  .howto {
    margin-top: 80px; }
    .howto h2 {
      font-weight: normal;
      text-align: center;
      line-height: 1.3;
      font-size: 28px; }
    .howto .sub_h2 {
      text-align: center;
      margin: 0;
      font-size: 13px; }
    .howto .howto_cont {
      border-top: 1px solid #231815;
      margin-top: 25px;
      padding-top: 25px; }
      .howto .howto_cont dl {
        display: flex;
        position: relative; }
        .howto .howto_cont dl:before {
          content: "";
          display: block;
          background: url(../images/second/dot.png) repeat-x;
          width: 100%;
          height: 2px;
          position: absolute;
          left: 0;
          top: 21px; }
        .howto .howto_cont dl dt {
          font-family: "Crimson Text", serif;
          padding: 0 15px 0 5px;
          background-color: #fff;
          position: relative;
          z-index: 1;
          font-size: 28px;
          width: 35px;
          margin-right: 68px; }
        .howto .howto_cont dl dd {
          background-color: #fff;
          position: relative;
          z-index: 1;
          font-size: 15px;
          width: calc(100% - 105px);
          padding-left: 20px;
          padding-top: 8px; }
  .product_used {
    background-color: #f2f2f2;
    padding: 70px 0 100px;
    margin-bottom: -48px; }
    .product_used .product {
      display: flex; }
      .product_used .product .img {
        text-align: center;
        width: 360px;
        padding-right: 30px; }
        .product_used .product .img img {
          max-width: 100%; }
      .product_used .product .text_area {
        width: calc(100% - 360px); }
        .product_used .product .text_area .used {
          color: #fff;
          background-color: #231815;
          line-height: 1;
          display: inline-block;
          padding: 6px 10px 3px;
          font-size: 14px; }
        .product_used .product .text_area .title {
          line-height: 1.2;
          font-weight: normal;
          text-transform: uppercase;
          font-size: 26px;
          margin-top: 15px; }
        .product_used .product .text_area .sub_title {
          font-family: "Shippori Mincho B1", serif;
          line-height: 1.2;
          font-size: 13px;
          margin: 5px 0 0 0; }
        .product_used .product .text_area .text {
          border-top: 1px solid #c8c8c8;
          font-size: 15px;
          padding-top: 25px;
          margin-top: 30px; }
        .product_used .product .text_area .btn {
          margin-top: 30px; }
          .product_used .product .text_area .btn .more_btn {
            border-radius: 3px;
            line-height: 1;
            display: inline-block;
            text-decoration: none;
            border: 1px solid #231815;
            transition: .3s;
            text-align: center;
            color: #231815;
            font-size: 16px;
            padding: 15px 40px;
            min-width: 255px; }
            .product_used .product .text_area .btn .more_btn:hover {
              background-color: #231815;
              color: #fff; }
  .product_main .common_inner {
    display: flex; }
    .product_main .common_inner .img {
      text-align: center;
      width: 50%; }
      .product_main .common_inner .img img {
        max-width: 100%; }
    .product_main .common_inner .text_area {
      width: 50%;
      padding-left: 60px; }
      .product_main .common_inner .text_area p.jp {
        margin: 0;
        font-size: 15px; }
      .product_main .common_inner .text_area p.en {
        margin: 30px 0 0 0; }
      .product_main .common_inner .text_area .price_link {
        margin: 30px 0 0 0;
        display: flex; }
        .product_main .common_inner .text_area .price_link .price {
          font-size: 28px;
          margin-left: 15px; }
          .product_main .common_inner .text_area .price_link .price span {
            font-size: 14px; }
        .product_main .common_inner .text_area .price_link .btn {
          margin-left: 40px; }
          .product_main .common_inner .text_area .price_link .btn .shop_btn {
            border-radius: 3px;
            line-height: 1;
            display: inline-block;
            text-decoration: none;
            border: 1px solid #231815;
            transition: .3s;
            text-align: center;
            color: #231815;
            position: relative;
            font-size: 16px;
            padding: 15px 70px 15px 60px;
            width: 100%; }
            .product_main .common_inner .text_area .price_link .btn .shop_btn:hover {
              background-color: #231815;
              color: #fff; }
              .product_main .common_inner .text_area .price_link .btn .shop_btn:hover .icon:before {
                background: url(../images/common/icon_cart_w.svg) no-repeat;
                background-size: cover;
                width: 16px;
                height: 16px; }
            .product_main .common_inner .text_area .price_link .btn .shop_btn .icon {
              display: inline-block; }
              .product_main .common_inner .text_area .price_link .btn .shop_btn .icon:before {
                content: "";
                display: inline-block;
                background: url(../images/common/icon_cart.svg) no-repeat;
                background-size: cover;
                width: 16px;
                height: 16px;
                transition: .2s;
                margin-right: 10px;
                transform: translateY(2px); }
  .product_wrap h2 {
    font-weight: normal;
    text-align: center;
    line-height: 1.3;
    font-size: 38px;
    margin-top: 110px; }
  .product_wrap .sub_h2 {
    text-align: center;
    margin: 0;
    font-size: 13px; }
  .product_wrap .feature_main {
    margin-top: 70px; }
    .product_wrap .feature_main img {
      max-width: 100%; }
  .product_wrap .feature_list {
    margin-top: 60px; }
    .product_wrap .feature_list .item {
      display: flex; }
      .product_wrap .feature_list .item:not(:first-of-type) {
        margin-top: 60px; }
      .product_wrap .feature_list .item .img {
        width: 203px; }
      .product_wrap .feature_list .item .text_area {
        width: calc(100% - 203px);
        padding-left: 20px; }
        .product_wrap .feature_list .item .text_area .title {
          line-height: 1.3;
          display: flex;
          align-items: center; }
          .product_wrap .feature_list .item .text_area .title .num {
            display: block;
            border-right: 1px solid #000;
            font-size: 20px;
            width: 35px;
            padding-top: 5px; }
          .product_wrap .feature_list .item .text_area .title h3 {
            font-weight: normal;
            font-size: 28px;
            width: calc(100% - 30px);
            padding-left: 15px; }
        .product_wrap .feature_list .item .text_area p.jp {
          margin: 20px 0 0 0; }
        .product_wrap .feature_list .item .text_area p.en {
          margin: 20px 0 0 0; }
  .product_info {
    background-color: #fff;
    padding: 60px; }
    .product_info dl {
      border-bottom: 1px solid #c9c9c9;
      display: flex; }
      .product_info dl dt {
        font-size: 15px;
        width: 110px;
        padding: 15px 20px 15px 0; }
      .product_info dl dd {
        font-size: 15px;
        width: calc(100% - 110px);
        padding: 15px 0; }
    .product_info .btn {
      text-align: center;
      margin-top: 30px; }
      .product_info .btn .shop_btn {
        border-radius: 3px;
        line-height: 1;
        display: inline-block;
        text-decoration: none;
        border: 1px solid #231815;
        transition: .3s;
        text-align: center;
        color: #231815;
        position: relative;
        font-size: 16px;
        padding: 15px 70px 15px 60px;
        width: 255px; }
        .product_info .btn .shop_btn:hover {
          background-color: #231815;
          color: #fff; }
          .product_info .btn .shop_btn:hover .icon:before {
            background: url(../images/common/icon_cart_w.svg) no-repeat;
            background-size: cover;
            width: 16px;
            height: 16px; }
        .product_info .btn .shop_btn .icon {
          display: inline-block; }
          .product_info .btn .shop_btn .icon:before {
            content: "";
            display: inline-block;
            background: url(../images/common/icon_cart.svg) no-repeat;
            background-size: cover;
            width: 16px;
            height: 16px;
            transition: .2s;
            margin-right: 10px;
            transform: translateY(2px); }
  .related_recipe {
    padding-bottom: 100px; }
    .related_recipe h2 {
      font-weight: normal;
      text-align: center;
      line-height: 1.3;
      font-size: 38px; }
    .related_recipe .sub_h2 {
      text-align: center;
      margin: 0;
      font-size: 13px; }
    .related_recipe .related_recipe_list {
      margin-top: 50px; }
      .related_recipe .related_recipe_list .item {
        overflow: hidden;
        position: relative;
        width: 366px;
        height: auto;
        margin: 0 2px; }
        .related_recipe .related_recipe_list .item .recipe_img {
          width: 100%;
          overflow: hidden;
          height: 250px; }
          .related_recipe .related_recipe_list .item .recipe_img a {
            width: 100%;
            height: 100%;
            display: block;
            text-decoration: none;
            color: #231815;
            transform: scale(1);
            transition: transform 1.2s cubic-bezier(0.24, 0.12, 0, 1); }
            .related_recipe .related_recipe_list .item .recipe_img a:hover {
              transform: scale(1.08);
              cursor: pointer; }
        .related_recipe .related_recipe_list .item h3 {
          color: #fff;
          display: inline-block;
          background-color: #231815;
          line-height: 1.2;
          font-weight: 600;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          padding: 10px 20px; }
      .related_recipe .related_recipe_list .slick-dots {
        text-align: left;
        margin-top: 30px; }
        .related_recipe .related_recipe_list .slick-dots li {
          display: inline-block;
          margin: 0 8px;
          padding-right: 10px; }
          .related_recipe .related_recipe_list .slick-dots li button {
            position: relative;
            text-indent: -9999px; }
            .related_recipe .related_recipe_list .slick-dots li button:before {
              content: '';
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: #fff;
              position: absolute;
              border: 1px solid #231815;
              top: 0;
              left: 0; }
          .related_recipe .related_recipe_list .slick-dots li.slick-active button:before {
            background-color: #231815; }
        .related_recipe .related_recipe_list .slick-dots button {
          margin: 0;
          padding: 0;
          background: none;
          border: none;
          border-radius: 0;
          outline: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none; }
      .related_recipe .related_recipe_list .slick-arrow {
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        padding: 0;
        appearance: none;
        font-size: 0;
        display: inline-block;
        width: 26px;
        height: 26px;
        position: absolute;
        z-index: 2; }
        .related_recipe .related_recipe_list .slick-arrow:hover {
          cursor: pointer;
          opacity: .7; }
        .related_recipe .related_recipe_list .slick-arrow.slick-prev {
          right: 0px;
          bottom: 0px; }
          .related_recipe .related_recipe_list .slick-arrow.slick-prev:after {
            content: "";
            width: 16px;
            height: 16px;
            border: 0px;
            border-bottom: solid 1px #231815;
            border-left: solid 1px #231815;
            transform: rotate(45deg);
            position: absolute;
            top: 0px;
            left: -50px;
            z-index: 2; }
        .related_recipe .related_recipe_list .slick-arrow.slick-next {
          right: 0px;
          bottom: 0px; }
          .related_recipe .related_recipe_list .slick-arrow.slick-next:after {
            content: "";
            width: 16px;
            height: 16px;
            border: 0px;
            border-top: solid 1px #231815;
            border-right: solid 1px #231815;
            transform: rotate(45deg);
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 2; } }
