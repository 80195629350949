body{
    @if $type == sp{
        padding-top: 55px;
    }
    @if $type == pc{
        padding-top: 70px;
    }
}
.contents{
    @if $type == sp{
        padding-bottom: 70px;
    }
    @if $type == pc{
        padding-bottom: 120px;
    }
    &.pB0{
        padding-bottom: 0;
    }
    section + section{
        @if $type == sp{
            margin-top: 60px;
            &.bg_gray{
                padding: 60px 0;
            }
        }
        @if $type == pc{
            margin-top: 110px;
            &.bg_gray{
                padding: 110px 0;
            }
        }
    }
    .bg_gray{
        background-color: #f1f1f1;
    }
}

.page_head_wrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @if $type == sp{
        width: 100%;
        height: 130px;
    }
    @if $type == pc{
        width: 100%;
        height: 215px;
    }
    .page_title{
        text-align: center;
        line-height: 1.3;
        text-transform: uppercase;
        font-weight: normal;
        @if $type == sp{
            font-size: 28px;
        }
        @if $type == pc{
            font-size: 38px;
        }
    }
    .page_sub_title{
        text-align: center;
        margin: 0;
        @if $type == sp{
            font-size: 11px;
        }
        @if $type == pc{
            font-size: 13px;
        }
    }
}

.recipe_list{
    @if $type == sp{
    }
    @if $type == pc{
        display: flex;
        flex-wrap: wrap;
    }
    > .item{
        overflow: hidden;
        position: relative;
        @if $type == sp{
            width: 100%;
            height: auto;
            + .item{
                margin-top: 40px;
            }
        }
        @if $type == pc{
            width: calc(100% / 3 - 2px);
            height: auto;
            &:not(:nth-of-type(3n)){
                margin-right: 3px;
            }
            &:nth-of-type(n + 4){
                margin-top: 80px;
            }
        }
        .recipe_img{
            width: 100%;
            overflow: hidden;
            @if $type == sp{
                height: 190px;
            }
            @if $type == pc{
                height: 250px;
            }
            a{
                width: 100%;
                height: 100%;
                display: block;
                text-decoration: none;
                color: $c_text;
                @if $type == sp{
                }
                @if $type == pc{
                    transform: scale(1);
                    transition: transform 1.2s cubic-bezier(.24, .12, 0, 1);
                    &:hover{
                        transform: scale(1.08);
                        cursor: pointer;
                    }
                }
            }
        }
        h3{
            color: #fff;
            display: inline-block;
            background-color: $c_base;
            line-height: 1.2;
            font-weight: 600;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            @if $type == sp{
                font-size: 16px;
                padding: 10px 15px;
            }
            @if $type == pc{
                padding: 10px 20px;
            }
        }
    }
    .product{
        margin-top: 10px;
        > .item{
            width: 100%;
            display: flex;
            align-items: center;
            &:nth-of-type(n + 2){
                margin-top: 5px;
            }
            .img{
                background-color: #f2f2f2;
                @if $type == sp{
                    width: 90px;
                    padding: 5px;
                    text-align: center;
                }
                @if $type == pc{
                    width: 100px;
                    padding: 5px;
                    text-align: center;
                }
                img{
                    max-width: 70%;
                }
            }
            .product_name{
                text-transform: uppercase;
                @if $type == sp{
                    width: calc(100% - 90px);
                    padding-left: 15px;
                }
                @if $type == pc{
                    width: calc(100% - 100px);
                    padding-left: 20px;
                }
                .title{
                    line-height: 1.2;
                    font-weight: 600;
                    @if $type == sp{
                        font-size: 18px;
                    }
                    @if $type == pc{
                        font-size: 20px;
                    }
                }
                .sub_title{
                    font-family: $ff_jp;
                    line-height: 1.2;
                    margin: 0;
                    @if $type == sp{
                        font-size: 13px;
                    }
                    @if $type == pc{
                        font-size: 12px;
                    }
                }
            }
        }
    }
}//recipe_list

.breadcrumbs{
    display: flex;
    @if $type==sp {
        margin: 0 20px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    @if $type==pc {
        width: 96%;
        margin: 0 auto;
        padding-top: 5px;
        padding-bottom: 20px;
        max-width: 1200px;
    }
    > span{
        display: inline-block;
        position: relative;
        padding-right: 15px;
        font-size: 13px;
        color: #000;
        @if $type==sp {
            font-size: 12px;
        }
        &:not(:last-of-type)::after{
            content: "／";
            display: inline-block;
            margin-left: 15px;
        }
        a{
            color: $c_text;
        }
    }
}//breadcrumb

.recipe_main{
    img{
        width: 100%;
    }
}
.recipe_wrap{
    @if $type == sp{
        padding: 50px 0;
    }
    @if $type == pc{
        padding: 95px 0;
    }
}
.ingredient{
    background-color: #f2f2f2;
    position: relative;
    @if $type == sp{
        width: calc(100% + 40px);
        padding: 45px 40px 40px;
        transform: translateX(-20px);
    }
    @if $type == pc{
        padding: 60px 100px;
    }
    h2{
        font-weight: normal;
        position: absolute;
        @if $type == sp{
            font-size: 23px;
            top: -1em;
            left: 50%;
            transform: translateX(-50%);
        }
        @if $type == pc{
            font-size: 28px;
            top: -1.1em;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .sub_h2{
        position: absolute;
        margin: 0;
        @if $type == sp{
            font-size: 11px;
            top: 1.2em;
            left: 50%;
            transform: translateX(-50%);
        }
        @if $type == pc{
            font-size: 13px;
            top: 1.2em;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .ingredient_cont{
        @if $type == sp{
        }
        @if $type == pc{
            display: flex;
            justify-content: space-between;
        }
        > div{
            @if $type == sp{
                &:last-of-type{
                    margin-top: 10px;
                }
            }
            @if $type == pc{
                width: calc(50% - 60px);
            }
            dl{
                display: flex;
                justify-content: space-between;
                position: relative;
                align-items: center;
                &:not(:first-of-type){
                    margin-top: 5px;
                }
                &:before{
                    content: "";
                    background: url(../images/second/dot.png) repeat-x;
                    width: 100%;
                    height: 2px;
                    position: absolute;
                    left: 0;
                    top: calc(50% - 1px);
                }
                dt{
                    background-color: #f2f2f2;
                    position: relative;
                    z-index: 1;
                    padding-right: 10px;
                    @if $type == sp{
                    }
                    @if $type == pc{
                    }
                }
                dd{
                    background-color: #f2f2f2;
                    position: relative;
                    z-index: 1;
                    padding-left: 10px;
                    @if $type == sp{
                    }
                    @if $type == pc{
                    }
                }
            }
        }
    }
}//ingredient

.howto{
    @if $type == sp{
        margin-top: 40px;
    }
    @if $type == pc{
        margin-top: 80px;
    }
    h2{
        font-weight: normal;
        text-align: center;
        line-height: 1.3;
        @if $type == sp{
            font-size: 23px;
        }
        @if $type == pc{
            font-size: 28px;
        }
    }
    .sub_h2{
        text-align: center;
        margin: 0;
        @if $type == sp{
            font-size: 11px;
        }
        @if $type == pc{
            font-size: 13px;
        }
    }
    .howto_cont{
        border-top: 1px solid $c_text;
        @if $type == sp{
            margin-top: 15px;
            padding-top: 15px;
        }
        @if $type == pc{
            margin-top: 25px;
            padding-top: 25px;
        }
        dl{
            display: flex;
            position: relative;
            @if $type == sp{
                + dl{
                    margin-top: 5px;
                }
            }
            @if $type == pc{
            }
            &:before{
                content: "";
                display: block;
                background: url(../images/second/dot.png) repeat-x;
                width: 100%;
                height: 2px;
                position: absolute;
                left: 0;
                top: 21px;
            }
            dt{
                font-family: $ff_en;
                padding: 0 15px 0 5px;
                background-color: #fff;
                position: relative;
                z-index: 1;
                @if $type == sp{
                    font-size: 28px;
                    width: 24px;
                    margin-right: 24px;
                }
                @if $type == pc{
                    font-size: 28px;
                    width: 35px;
                    margin-right: 68px;
                }
            }
            dd{
                background-color: #fff;
                position: relative;
                z-index: 1;
                @if $type == sp{
                    font-size: 15px;
                    width: calc(100% - 48px);
                    padding-left: 10px;
                    padding-top: 8px;
                }
                @if $type == pc{
                    font-size: 15px;
                    width: calc(100% - 105px);
                    padding-left: 20px;
                    padding-top: 8px;
                }
            }
        }
    }
}//howto

.product_used{
    background-color: #f2f2f2;
    @if $type == sp{
        padding: 40px 0 110px;
        margin-bottom: -61px;
    }
    @if $type == pc{
        padding: 70px 0 100px;
        margin-bottom: -48px;
    }
    .product{
        @if $type == sp{
        }
        @if $type == pc{
            display: flex;
        }
        .img{
            text-align: center;
            @if $type == sp{
            }
            @if $type == pc{
                width: 360px;
                padding-right: 30px;
            }
            img{
                max-width: 100%;
            }
        }
        .text_area{
            @if $type == sp{
                text-align: center;
                margin-top: 20px;
            }
            @if $type == pc{
                width: calc(100% - 360px);
            }
            .used{
                color: #fff;
                background-color: $c_base;
                line-height: 1;
                display: inline-block;
                padding: 6px 10px 3px;
                @if $type == sp{
                }
                @if $type == pc{
                    font-size: 14px;
                }
            }
            .title{
                line-height: 1.2;
                font-weight: normal;
                text-transform: uppercase;
                @if $type == sp{
                    font-size: 20px;
                    margin-top: 10px;
                }
                @if $type == pc{
                    font-size: 26px;
                    margin-top: 15px;
                }
            }
            .sub_title{
                font-family: $ff_jp;
                line-height: 1.2;
                @if $type == sp{
                    font-size: 11px;
                    margin: 3px 0 0 0;
                }
                @if $type == pc{
                    font-size: 13px;
                    margin: 5px 0 0 0;
                }
            }
            .text{
                border-top: 1px solid #c8c8c8;
                @if $type == sp{
                    padding-top: 15px;
                    margin-top: 15px;
                    text-align: left;
                }
                @if $type == pc{
                    font-size: 15px;
                    padding-top: 25px;
                    margin-top: 30px;
                }
            }
            .btn{
                @if $type == sp{
                    text-align: center;
                    margin-top: 20px;
                }
                @if $type == pc{
                    margin-top: 30px;
                }
                .more_btn{
                    border-radius: 3px;
                    line-height: 1;
                    display: inline-block;
                    text-decoration: none;
                    border: 1px solid $c_text;
                    transition: .3s;
                    text-align: center;
                    color: $c_text;
                    @if $type == sp{
                        font-size: 14px;
                        padding: 15px 40px;
                        width: 90%;
                    }
                    @if $type == pc{
                        font-size: 16px;
                        padding: 15px 40px;
                        min-width: 255px;
                        &:hover{
                            background-color: #231815;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}//product_used

.product_main{
    @if $type == sp{
    }
    @if $type == pc{
    }
    .common_inner{
        @if $type == sp{
        }
        @if $type == pc{
            display: flex;
        }
        .img{
            text-align: center;
            @if $type == sp{
            }
            @if $type == pc{
                width: 50%;
            }
            img{
                max-width: 100%;
            }
        }
        .text_area{
            @if $type == sp{
                margin-top: 30px;
            }
            @if $type == pc{
                width: 50%;
                padding-left: 60px;
            }
            p.jp{
                margin: 0;
                @if $type == sp{
                }
                @if $type == pc{
                    font-size: 15px;
                }
            }
            p.en{
                @if $type == sp{
                    margin: 30px 0 0 0;
                }
                @if $type == pc{
                    margin: 30px 0 0 0;
                }
            }
            .price_link{
                @if $type == sp{
                    margin: 30px 0 0 0;
                }
                @if $type == pc{
                    margin: 30px 0 0 0;
                    display: flex;
                }
                .price{
                    @if $type == sp{
                        text-align: center;
                        font-size: 28px;
                        span{
                            font-size: 14px;
                        }
                    }
                    @if $type == pc{
                        font-size: 28px;
                        margin-left: 15px;
                        span{
                            font-size: 14px;
                        }
                    }
                }
                .btn{
                    @if $type == sp{
                        text-align: center;
                        margin-top: 10px;
                    }
                    @if $type == pc{
                        margin-left: 40px;
                    }
                    .shop_btn{
                        border-radius: 3px;
                        line-height: 1;
                        display: inline-block;
                        text-decoration: none;
                        border: 1px solid $c_text;
                        transition: .3s;
                        text-align: center;
                        color: $c_text;
                        position: relative;
                        @if $type == sp{
                            font-size: 14px;
                            padding: 15px 40px 15px 30px;
                            width: 90%;
                        }
                        @if $type == pc{
                            font-size: 16px;
                            padding: 15px 70px 15px 60px;
                            width: 100%;
                            &:hover{
                                background-color: #231815;
                                color: #fff;
                                .icon:before{
                                    background: url(../images/common/icon_cart_w.svg) no-repeat;
                                    background-size: cover;
                                    width: 16px;
                                    height: 16px;
                                }
                            }
                        }
                        .icon{
                            display: inline-block;
                            &:before{
                                content: "";
                                display: inline-block;
                                background: url(../images/common/icon_cart.svg) no-repeat;
                                background-size: cover;
                                width: 16px;
                                height: 16px;
                                transition: .2s;
                                margin-right: 10px;
                                transform: translateY(2px);
                            }
                        }
                    }
                }
            }
        }
    }
}//product_main

.product_wrap{
    @if $type == sp{
    }
    @if $type == pc{
    }
    h2{
        font-weight: normal;
        text-align: center;
        line-height: 1.3;
        @if $type == sp{
            font-size: 28px;
            margin-top: 60px;
        }
        @if $type == pc{
            font-size: 38px;
            margin-top: 110px;
        }
    }
    .sub_h2{
        text-align: center;
        margin: 0;
        @if $type == sp{
            font-size: 11px;
        }
        @if $type == pc{
            font-size: 13px;
        }
    }
    .feature_main{
        @if $type == sp{
            margin-top: 40px;
        }
        @if $type == pc{
            margin-top: 70px;
        }
        img{
            max-width: 100%;
        }
    }
    .feature_list{
        @if $type == sp{
            margin-top: 60px;
        }
        @if $type == pc{
            margin-top: 60px;
        }
        .item{
            @if $type == sp{
                &:not(:first-of-type){
                    margin-top: 60px;
                }
            }
            @if $type == pc{
                display: flex;
                &:not(:first-of-type){
                    margin-top: 60px;
                }
            }
            .img{
                @if $type == sp{
                    text-align: center;
                }
                @if $type == pc{
                    width: 203px;
                }
            }
            .text_area{
                @if $type == sp{
                    margin-top: 20px;
                }
                @if $type == pc{
                    width: calc(100% - 203px);
                    padding-left: 20px;
                }
                .title{
                    line-height: 1.3;
                    @if $type == sp{
                        display: flex;
                        align-items: center;
                    }
                    @if $type == pc{
                        display: flex;
                        align-items: center;
                    }
                    .num{
                        display: block;
                        border-right: 1px solid #000;
                        @if $type == sp{
                            font-size: 18px;
                            width: 30px;
                            padding-top: 5px;
                        }
                        @if $type == pc{
                            font-size: 20px;
                            width: 35px;
                            padding-top: 5px;
                        }
                    }
                    h3{
                        font-weight: normal;
                        @if $type == sp{
                            font-size: 19px;
                            width: calc(100% - 30px);
                            padding-left: 15px;
                        }
                        @if $type == pc{
                            font-size: 28px;
                            width: calc(100% - 30px);
                            padding-left: 15px;
                        }
                    }
                }
                p.jp{
                    @if $type == sp{
                        margin: 20px 0 0 0;
                    }
                    @if $type == pc{
                        margin: 20px 0 0 0;
                    }
                }
                p.en{
                    @if $type == sp{
                        margin: 20px 0 0 0;
                    }
                    @if $type == pc{
                        margin: 20px 0 0 0;
                    }
                }
            }
        }
    }
}//product_wrap

.product_info{
    background-color: #fff;
    @if $type == sp{
        padding: 30px 30px 45px;
    }
    @if $type == pc{
        padding: 60px;
    }
    dl{
        border-bottom: 1px solid #c9c9c9;
        @if $type == sp{
            padding: 15px 0;
        }
        @if $type == pc{
            display: flex;
        }
        dt{
            @if $type == sp{
                margin-bottom: 10px;
            }
            @if $type == pc{
                font-size: 15px;
                width: 110px;
                padding: 15px 20px 15px 0;
            }
        }
        dd{
            @if $type == sp{
            }
            @if $type == pc{
                font-size: 15px;
                width: calc(100% - 110px);
                padding: 15px 0;
            }
        }
    }
    .btn{
        text-align: center;
        @if $type == sp{
            margin-top: 30px;
        }
        @if $type == pc{
            margin-top: 30px;
        }
        .shop_btn{
            border-radius: 3px;
            line-height: 1;
            display: inline-block;
            text-decoration: none;
            border: 1px solid $c_text;
            transition: .3s;
            text-align: center;
            color: $c_text;
            position: relative;
            @if $type == sp{
                font-size: 14px;
                padding: 15px 40px 15px 30px;
                width: 100%;
            }
            @if $type == pc{
                font-size: 16px;
                padding: 15px 70px 15px 60px;
                width: 255px;
                &:hover{
                    background-color: #231815;
                    color: #fff;
                    .icon:before{
                        background: url(../images/common/icon_cart_w.svg) no-repeat;
                        background-size: cover;
                        width: 16px;
                        height: 16px;
                    }
                }
            }
            .icon{
                display: inline-block;
                &:before{
                    content: "";
                    display: inline-block;
                    background: url(../images/common/icon_cart.svg) no-repeat;
                    background-size: cover;
                    width: 16px;
                    height: 16px;
                    transition: .2s;
                    margin-right: 10px;
                    transform: translateY(2px);
                }
            }
        }
    }
}//product_info

.related_recipe{
    @if $type == sp{
        padding-bottom: 50px;
    }
    @if $type == pc{
        padding-bottom: 100px;
    }
    h2{
        font-weight: normal;
        text-align: center;
        line-height: 1.3;
        @if $type == sp{
            font-size: 28px;
        }
        @if $type == pc{
            font-size: 38px;
        }
    }
    .sub_h2{
        text-align: center;
        margin: 0;
        @if $type == sp{
            font-size: 11px;
        }
        @if $type == pc{
            font-size: 13px;
        }
    }
    .related_recipe_list{
        @if $type == sp{
            margin-top: 30px;
        }
        @if $type == pc{
            margin-top: 50px;
        }
        .item{
            overflow: hidden;
            position: relative;
            @if $type == sp{
                width: 100%;
                height: auto;
            }
            @if $type == pc{
                width: 366px;
                height: auto;
                margin: 0 2px;
            }
            .recipe_img{
                width: 100%;
                overflow: hidden;
                @if $type == sp{
                    height: 230px;
                }
                @if $type == pc{
                    height: 250px;
                }
                a{
                    width: 100%;
                    height: 100%;
                    display: block;
                    text-decoration: none;
                    color: $c_text;
                    @if $type == sp{
                    }
                    @if $type == pc{
                        transform: scale(1);
                        transition: transform 1.2s cubic-bezier(.24, .12, 0, 1);
                        &:hover{
                            transform: scale(1.08);
                            cursor: pointer;
                        }
                    }
                }
            }
            h3{
                color: #fff;
                display: inline-block;
                background-color: $c_base;
                line-height: 1.2;
                font-weight: 600;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                @if $type == sp{
                    font-size: 16px;
                    padding: 10px 15px;
                }
                @if $type == pc{
                    padding: 10px 20px;
                }
            }
        }
        .slick-dots{
            text-align: left;
            @if $type == sp{
                padding-left: 15px;
                padding-right: 70px;
            }
            @if $type == pc{
                margin-top: 30px;
            }
            li{
                display: inline-block;
                margin: 0 8px;
                padding-right: 10px;
                @if $type == sp{
                    margin: 0 5px;
                }
                button{
                    position: relative;
                    text-indent: -9999px;
                    &:before{
                        content: '';
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: #fff;
                        position: absolute;
                        border: 1px solid $c_base;
                        top: 0;
                        left: 0;
                        @if $type == sp{
                            width: 6px;
                            height: 6px;
                        }
                    }
                }
                &.slick-active{
                    button:before{
                        background-color: $c_base;
                    }
                }
            }
            button{
                margin: 0;
                padding: 0;
                background: none;
                border: none;
                border-radius: 0;
                outline: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
            }
        }//slick-dots
        .slick-arrow{
            background-color: transparent;
            border: none;
            cursor: pointer;
            outline: none;
            padding: 0;
            appearance: none;
            font-size: 0;
            display: inline-block;
            width: 26px;
            height: 26px;
            position: absolute;
            z-index: 2;
            &:hover{
                cursor: pointer;
                opacity: .7;
            }
            &.slick-prev{
                right: 0px;
                bottom: 0px;
                &:after{
                    content: "";
                    width: 16px;
                    height: 16px;
                    border: 0px;
                    border-bottom: solid 1px $c_base;
                    border-left: solid 1px $c_base;
                    transform: rotate(45deg);
                    position: absolute;
                    top: 0px;
                    left: -50px;
                    z-index: 2;
                    @if $type == sp{
                        left: -50px;
                        width: 10px;
                        height: 10px;
                    }
                }
            }
            &.slick-next{
                right: 0px;
                bottom: 0px;
                @if $type == sp{
                    right: 5px;
                }
                &:after{
                    content: "";
                    width: 16px;
                    height: 16px;
                    border: 0px;
                    border-top: solid 1px $c_base;
                    border-right: solid 1px $c_base;
                    transform: rotate(45deg);
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    z-index: 2;
                    @if $type == sp{
                        width: 10px;
                        height: 10px;
                    }
                }
            }
        }//slick-arrow
    }//related_recipe_list
}//related_recipe


.sample{
    @if $type == sp{
    }
    @if $type == pc{
    }
}
